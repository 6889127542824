<template>
  <v-dialog v-model="dialog" class="dialog" max-width="650" persistent>
    <v-card class="card py-10">
      <h2 class="text-center font-weight-regular">
        {{ dialogItem.status === 1 ? $t("sure_pause") : $t("sure_activate") }}
      </h2>
      <v-row>
        <v-col class="mt-7 mb-4 text-center" cols="12">
          <v-btn
            class="mr-5 font-weight-bold"
            style="box-shadow: none"
            @click="$emit('functionAgree', dialogItem.id)"
            height="42"
            :loading="isPausing"
            >
            {{ $t("yes") }}
          </v-btn>
          <v-btn
            class="button font-weight-bold"
            height="42"
            @click="$emit('functionCancel')"
            >{{ $t("no") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>
<script>
import {CAMPAIGN_STATUSES} from "@/utils";

export default {
  name: "PauseActivateModal",
  props: {
    dialog: Boolean,
    isPausing: Boolean,
    dialogItem: Object,
  },
  computed: {
    GETCAMPAIGN_STATUS() {
      return CAMPAIGN_STATUSES;
    },
  }
};
</script>
